import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useQuery } from 'react-apollo';

import query from '../../../../api/export-planning-data-in/query';

const Page: FC<PageProps> = ({ params: { id } }) => {
  const { data, error, loading } = useQuery(query, {
    variables: {
      id,
    },
  });

  if (error || loading) {
    return null;
  }

  const planning: any = data.planningsRetrieve;

  if (!planning || !planning.activities || !planning.places) {
    return null;
  }

  const body = {
    data: {
      activities: planning.activities,
      places: planning.places,
      vehicles: planning.vehicles,
    },
  };

  return <pre>{JSON.stringify(body, null, 2)}</pre>;
};

export default Page;
