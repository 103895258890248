import gql from 'graphql-tag';

export default gql`
  query planningsRetrieve($id: String!) {
    planningsRetrieve(id: $id) {
      id
      activities {
        id
        deliveries {
          id
          containers {
            id
            depth
            hasPallet
            height
            isRefrigerated
            isStackable
            name
            quantity
            temperature
            weight
            width
          }
          placeId
        }
        placeId
        userId
      }
      places {
        id
        formattedAddress
        latitude
        longitude
        name
      }
      vehicles {
        id
        arrivalPlaceId
        coefHour
        coefKm
        coefPec
        coefVolume
        coefWeight
        departurePlaceId
        hasHatch
        hasPalletTruck
        isRefrigerated
        name
        userId
        volume
        weight
      }
    }
  }
`;
